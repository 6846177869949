const report_reason = {
    user : [
        '전문 판매업자 같아요', 
        '매너가 안좋아요', 
    ],
    product : [
        '거래 금지 품목이에요', 
        '중고거래 게시글이 아니에요', 
        '거래 중 분쟁이 발생했어요', 
        '사기인 것 같아요', 
        '중복 및 도배된 게시글이에요', 
        '나에게 구매 후 비싸게 재판매했어요',
    ],
    board : [
        "혐오 표현 및 혐오감을 일으키는 게시글",
        "광고성 게시글",
        "유해한 내용의 게시글",
        "부적절한 게시글",
        "선정적인 게시글",
    ], 
    reply : [
        "혐오 표현 및 혐오감을 일으키는 답글",
        "광고성 답글",
        "유해한 내용의 답글",
        "부적절한 답글",
        "선정적인 답글",
    ]
}

export default report_reason;